export function formatToUsd(amount = 0, currency = "USD") {

    if (currency === "USD") {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(amount);
    } else {
        return amount;
    }

}

export function formatCompactNumber(amount) {
    const absAmount = Math.abs(amount);
    let formattedAmount;

    if (absAmount >= 1e6) {
        formattedAmount = (Math.floor(absAmount / 1e5) / 10) + 'M'; // For millions
    } else if (absAmount >= 1e3) {
        formattedAmount = (Math.floor(absAmount / 1e2) / 10) + 'K'; // For thousands
    } else {
        formattedAmount = Math.floor(absAmount).toString(); // For less than 1000
    }

    // Ensure the final format includes the dollar sign and is within 6 characters
    if (formattedAmount.length + 1 > 6) {
        // If formatting fails to stay within 6 characters, floor more aggressively
        if (formattedAmount.includes('.')) {
            formattedAmount = formattedAmount.split('.')[0] + formattedAmount.slice(-1);
        } else {
            formattedAmount = formattedAmount.substring(0, 5);
        }
    }

    return `${formattedAmount}`;
}

export function formatCompactCurrency(amount, currency = "USD") {

    if(amount == 0) {
        if (currency === "USD") {
            return `$0`;
        }

        return `0 ${currency}`;
    }

    const absAmount = Math.abs(amount);
    let formattedAmount = formatCompactNumber(absAmount);

    if (currency === "USD") {
        return `$${formattedAmount}`;
    }

    return `${formattedAmount} ${currency}`;
}

export function formatNumberUSA(number, decimalPlaces = 0) {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    }).format(number);
}

export function formatEstimatedValueUsd(value) {
    if (value === 0)
        return "$0";

    let decimalPlaces = 0;

    if (value < 100) {
        decimalPlaces = 2
    } else if (value < 1000) {
        decimalPlaces = 1
    }

    return "~$" + formatNumberUSA(value, decimalPlaces);
}

export function formatEstimatedValue(value) {
    if (value === 0)
        return "0";

    return "~" + formatNumberUSA(value, 0);
}

export function formatNumberWithCurrency(number, currency = "") {
    return new Intl.NumberFormat('en-US').format(number) + " " + currency;
}

export function prepareCurrencies(budgetObject) {
    let usdAmount = budgetObject?.estimatedValueUsd ?? 0;

    let currencies = {
        "USD": (usdAmount > 0 ? "~" : "") + formatCompactCurrency(usdAmount),
    };

    if (budgetObject?.currency !== "USD") {
        currencies[budgetObject?.currency] = formatNumberUSA(budgetObject?.amount);
    }

    return currencies;
}